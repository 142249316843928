import React from "react";
import { navigate } from "gatsby";
import { withAuthentication } from "../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../components/main";
import ScreenLoad from "../../components/screenLoad";
import Button from "../../components/button";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import MissingDataPanel from "../../components/missingDataPanel";
import ContentArea from "../../components/contentArea";
import Checkbox from "../../components/checkbox";
import Consts from "../../config/consts";
import Api from "../../config/api";
import Common from "../../config/common";
import Provider from "../../config/provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class Create extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                initialising: true,
                firstName: Common.objectPropertyExists(this.props, "auth.user.firstName", null),

                age_requisite: Common.objectPropertyExists(this.props, "auth.user.requisites.ageOver", 0),
                display_name_requisite: Common.objectPropertyExists(this.props, "auth.user.requisites.displayName", false),
                terms_requisite: Common.objectPropertyExists(this.props, "auth.user.requisites.termsOfService", null),
                privacy_requisite: Common.objectPropertyExists(this.props, "auth.user.requisites.privacyPolicy", null),

                // If we are requiring the DisplayName to be setup then its likely
                // safe to assume that
                initialSetup: Common.objectPropertyExists(this.props, "auth.user.requisites.displayName", false),

                displayName: "",
                displayNameAvailability: null,
                isCheckingDisplayName: false,
                displayNameCheckTimer: null,

                terms_data: null,
                privacy_data: null,

                agreed_to_age: false,
                agreed_to_terms: false,
                agreed_to_privacy: false,

                attention_displayname: false,
                attention_agreements: false,
            };

            // Check that we have some requisites to handle
            if (Object.keys(Common.objectPropertyExists(this.props, "auth.user.requisites", {})).length <= 0) {
                navigate("/");
            } else {
                this.state.initialising = false;
            }
        }

        componentDidMount() {
            // Fetch the terms/privacy data
            if (this.state.terms_requisite !== null) {
            }
            if (this.state.privacy_requisite !== null) {
            }
        }

        onAgeConfirmation = (id, value) => {
            this.setState(
                {
                    attention_agreements: false,
                    agreed_to_age: value,
                },
                () => {}
            );
        };
        onTermsConfirmation = (id, value) => {
            this.setState(
                {
                    attention_agreements: false,
                    agreed_to_terms: value,
                },
                () => {}
            );
        };
        onPrivacyConfirmation = (id, value) => {
            this.setState(
                {
                    attention_agreements: false,
                    agreed_to_privacy: value,
                },
                () => {}
            );
        };

        onDisplayNameUpdated = (text) => {
            clearTimeout(this.state.displayNameCheckTimer);

            // Run a basic check to confirm the displayname looks ok
            if (!text || text.length <= 0) {
                // Invalid displayname
                this.setState({
                    displayNameAvailability: null,
                });
            } else {
                // Wait for a second before we make a call to the back-end to check the displayname
                // adding a delay here allows for further changes to be made without us constantly
                // ambushing the back-end
                this.state.displayNameCheckTimer = setTimeout(() => {
                    // Make call to service to check the availability
                    this.setState(
                        {
                            displayName: text,
                            attention_displayname: false,
                            isCheckingDisplayName: true,
                        },
                        () => {
                            Api.market({
                                endpoint: "/user/verify/displayname",
                                method: "GET",
                                data: {
                                    displayName: text,
                                },
                            })
                                .then((res) => {
                                    console.log("Received response from checkDisplaynameAvailability", res);

                                    // Done - display outcome
                                    this.setState({
                                        isCheckingDisplayName: false,
                                        displayNameAvailability: res.availability,
                                        hasUpdatedDisplayName: res.availability === "NOT_TAKEN",
                                    });
                                })
                                .catch((e) => {
                                    // Failed call
                                    this.setState(
                                        {
                                            isCheckingDisplayName: false,
                                            displayNameAvailability: null,
                                        },
                                        () => {}
                                    );
                                });
                        }
                    );
                }, 500);
            }
        };

        /**
         * Validate the provided information to check we have everything prior to
         * continuing. Running this function will automatically display error messages
         * and will return a bool as to whether any errors were found.
         */
        validate = () => {
            let foundDisplayNameIssue = false;
            let foundAgreementIssue = false;

            // Check if we needed a displayname
            if (this.state.display_name_requisite === true && this.state.displayNameAvailability !== "NOT_TAKEN") {
                foundDisplayNameIssue = true;
            }

            // Check we have all agreements agreed to
            if (this.state.age_requisite !== 0 && this.state.agreed_to_age !== true) {
                foundAgreementIssue = true;
            }
            if (this.state.terms_requisite !== null && this.state.agreed_to_terms !== true) {
                foundAgreementIssue = true;
            }
            if (this.state.privacy_requisite !== null && this.state.agreed_to_privacy !== true) {
                foundAgreementIssue = true;
            }

            // Update the state
            this.setState({
                attention_displayname: foundDisplayNameIssue,
                attention_agreements: foundAgreementIssue,
            });

            return !foundDisplayNameIssue && !foundAgreementIssue;
        };

        /**
         * Save the changes to the user account - signature is require to verify the changes
         * being made.
         */
        onSave = () => {
            if (this.validate()) {
                // Build up a list of data that needs updating
                let request_data = {};
                if (this.state.age_requisite !== 0 && this.state.agreed_to_age === true) {
                    if (!request_data.hasOwnProperty("userAgreed")) {
                        request_data["userAgreed"] = [];
                    }
                    request_data["userAgreed"].push("AGE_OVER");
                }
                if (this.state.terms_requisite !== null && this.state.agreed_to_terms === true) {
                    if (!request_data.hasOwnProperty("userAgreed")) {
                        request_data["userAgreed"] = [];
                    }
                    request_data["userAgreed"].push("TERMS_AND_CONDITIONS");
                }
                if (this.state.privacy_requisite !== null && this.state.agreed_to_privacy === true) {
                    if (!request_data.hasOwnProperty("userAgreed")) {
                        request_data["userAgreed"] = [];
                    }
                    request_data["userAgreed"].push("PRIVACY_POLICY");
                }
                if (this.state.display_name_requisite === true && this.state.displayNameAvailability === "NOT_TAKEN") {
                    request_data.displayName = this.state.displayName;
                }
                console.log({ request_data });

                // Request a signature from the user
                Provider.SignMessageWithAccountWallet(this.props.auth.user.addresses)
                    .then((signature) => {
                        // Push the signature into the request
                        request_data.signature = signature;

                        // Make the call to update the user
                        Api.market({
                            endpoint: "/user",
                            method: "PATCH",
                            data: request_data,
                        })
                            .then(() => {
                                // Successfully saved user we should be OK to move elsewhere now
                                navigate("/");
                            })
                            .catch((e) => {
                                // Failed
                            });
                    })
                    .catch((e) => {});
            }
        };

        render() {
            return (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea slim={true} centered={false} extraTopPadding={true} bottomRule={true}>
                        <TextArea center={true}>
                            <p className={"caps_and_spaced"}>{this.state.initialSetup ? `ACCOUNT SETUP` : `ACCOUNT CHANGES`}</p>
                        </TextArea>
                        <TextArea center={true}>
                            {this.state.initialSetup ? (
                                <p>{`Hi! and thanks for signing up! :) There are a few more steps we need to run through (including some legal bits) to complete your account setup, please review the requirements below and then 'Continue' when you're done.`}</p>
                            ) : (
                                <p>{`Hi${
                                    this.state.firstName ? " " + this.state.firstName : ""
                                }! We have made a few alterations to the terms you originally agreed to when you signed up initially, could you please review the requirements below and then 'Continue' when you're done.`}</p>
                            )}
                            <br />
                            <br />
                        </TextArea>
                    </ContentArea>
                    {this.state.display_name_requisite === true && (
                        <ContentArea slim={true} centered={false} bottomRule={true}>
                            <TextArea header={"Display Name"} center={false}>
                                <p>{`You need to pick a unique Display Name, this display name will be used to identify your account and profile.`}</p>
                                <p>{`Your display name must start and end with either a A-Z letter or a number 0-9, names are not case-sensitive. You can also use special characters ("-", "_" or ".") as seperators but these must not follow another symbol.`}</p>
                                <Input
                                    placeholder={"Enter your desired Display Name"}
                                    onTextChanged={this.onDisplayNameUpdated}
                                    id="diplayName"
                                    name="displayName"
                                    defaultValue={this.state.displayName}
                                />
                                {this.state.isCheckingDisplayName ? (
                                    <span className="info">
                                        <FontAwesomeIcon icon={faSpinner} className={"animate_rotate"} />
                                        &nbsp;&nbsp; Checking display name availability...
                                    </span>
                                ) : this.state.displayNameAvailability === "TAKEN" ? (
                                    <span className="info">
                                        <FontAwesomeIcon icon={faTimes} className={"green"} />
                                        &nbsp;&nbsp; This displayname is taken - try another!
                                    </span>
                                ) : this.state.displayNameAvailability === "NOT_TAKEN" ? (
                                    <span className="info">
                                        <FontAwesomeIcon icon={faCheck} className={"green"} />
                                        &nbsp;&nbsp; This displayname is available!
                                    </span>
                                ) : (
                                    this.state.displayNameAvailability === "INVALID" && (
                                        <span className="info">
                                            <FontAwesomeIcon icon={faTimes} />
                                            &nbsp;&nbsp; The provided displayname is not valid - please check the provided name abides by the above rules.
                                        </span>
                                    )
                                )}
                            </TextArea>
                            {this.state.attention_displayname && (
                                <TextArea border={true} center={true}>
                                    <h4 className={"red"}>Invalid Display Name</h4>
                                    <p className={"red"}>
                                        Please pick out a unique displayname by typing in your requested name. If the name is taken <FontAwesomeIcon icon={faTimes} className={"red"} /> try typing in a
                                        different name until you find one that is available <FontAwesomeIcon icon={faCheck} className={"green"} />.
                                    </p>
                                </TextArea>
                            )}
                        </ContentArea>
                    )}
                    {(this.state.age_requisite !== 0 || this.state.terms_requisite !== null || this.state.privacy_requisite !== null) && (
                        <ContentArea slim={true}>
                            <TextArea header={"Agreements"}>
                                <p>{`Please read (including any associated linked documents) and agree to the following statements. Each of the following must be agreed in order for you to hold an account with us.`}</p>
                                <table className={styles.accept_table}>
                                    {this.state.age_requisite !== 0 && (
                                        <tr>
                                            <td>
                                                <Checkbox checked={this.state.agreed_to_age} id={"agree_age"} onCheckChanged={this.onAgeConfirmation} />
                                            </td>
                                            <td>
                                                <p className={styles.agreement_text}>{`I confirm that I am aged ${this.state.age_requisite} or over.`}</p>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.terms_requisite !== null && (
                                        <tr>
                                            <td>
                                                <Checkbox checked={this.state.agreed_to_terms} id={"agree_terms"} onCheckChanged={this.onTermsConfirmation} />
                                            </td>
                                            <td>
                                                <p className={styles.agreement_text}>{`I agree to the Terms of Service.`}</p>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.privacy_requisite !== null && (
                                        <tr>
                                            <td>
                                                <Checkbox checked={this.state.agreed_to_privacy} id={"agree_privacy"} onCheckChanged={this.onPrivacyConfirmation} />
                                            </td>
                                            <td>
                                                <p className={styles.agreement_text}>{`I agree to the Privacy Policy.`}</p>
                                            </td>
                                        </tr>
                                    )}
                                </table>
                            </TextArea>
                            {this.state.attention_agreements && (
                                <TextArea border={true} center={true}>
                                    <h4 className={"red"}>Missing Acceptance</h4>
                                    <p className={"red"}>
                                        Please confirm that you have agreed to all agreements listed above. Each agreement displayed is a requirement for holding an account with us.
                                    </p>
                                </TextArea>
                            )}
                            <br />
                        </ContentArea>
                    )}
                    <ContentArea slim={true}>
                        <Button text="Continue" large={true} onClick={this.onSave} />
                    </ContentArea>
                </Main>
            );
        }
    }
);
